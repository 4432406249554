import React from 'react'

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      organization: '',
      message: `Hi Matt – I'd like to learn more about Typist. Looking forward to connecting.`,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="w-100"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div className="mb-typist16 w-100">
          <label
            htmlFor="contactUsName"
            className="form-label fw-bold"
            required
          >
            Name
          </label>
          <input
            type="text"
            className="form-control"
            name="name"
            value={this.state.name}
            onChange={this.handleInputChange}
            id="contactUsName"
          />
          {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
        </div>
        <div className="mb-typist16 w-100">
          <label htmlFor="contactUsEmail" className="form-label fw-bold">
            Email address
          </label>
          <input
            type="email"
            name="email"
            onChange={this.handleInputChange}
            className="form-control"
            value={this.state.email}
            id="contactUsEmail"
            required
          />
          {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
        </div>
        <div className="mb-typist16">
          <label htmlFor="contactUsOrganization" className="form-label fw-bold">
            Organization
          </label>
          <input
            type="text"
            name="organization"
            onChange={this.handleInputChange}
            value={this.state.organization}
            className="form-control"
            id="contactUsOrganization"
          />
          {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
        </div>
        <div className="mb-typist16">
          <label htmlFor="message" className="form-label fw-bold">
            Message (optional)
          </label>
          <textarea
            className="form-control"
            onChange={this.handleInputChange}
            id="message"
            rows="6"
            name="message"
            value={this.state.message}
          />
        </div>
        {/* <div className="mb-3 form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
                            </div> */}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
        {/* <label>
          Name:
          <input
            type="text"
            value={this.state.name}
            onChange={this.handleInputChange}
          />
        </label> */}
        {/* <input type="submit" value="Submit" /> */}
      </form>
    )
  }
}

const ContactSection = () => (
  <section className="container mt-typist56 mt-md-typist64 mt-xl-typist96">
    <div className="row justify-content-center">
      <div className="col-12 col-md-8 col-lg-6">
        <h1 className="fs-2 text-center mb-typist32 mb-md-typist64 fw-bold">
          Contact Us
        </h1>
        <p>
          Learn why faculty everywhere are switching their curriculum to Typist.
          Fill out our form below and Matt, our Founder, will get back to you
          shortly.
        </p>
        <p>
          Alternatively, write Matt directly:{' '}
          <a href="mailto:matt@typistapp.ca">matt@typistapp.ca</a>
        </p>

        <div className="row">
          <div className="col-12 ">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ContactSection

// const ContactForm = () => {
//   const [name, setName] = useState('')
//   const [email, setEmail] = useState('')
//   const [organization, setOrganization] = useState('')
//   const [message, setMessage] = useState('')
//   const [flashMessage, setFlashMessage] = useState(null)

//   const handleSubmit = e => {
//     // Do not submit form via HTTP, since we're doing that via XHR request.
//     e.preventDefault()

//     // Note: Netlify does not support json, using URLSearchParams
//     // and specifying url encoded content-type is in Netlify docs
//     fetch('/', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//       body: new URLSearchParams({
//         'form-name': 'contact',
//         name,
//         email,
//         organization,
//         message,
//       }).toString(),
//     })
//       .then(res => {
//         // fetch does not reject non 200 status codes, so make sure status is ok
//         if (res.ok || true) {
//           // Notify user with a success message and clear the form to prevent submitting multiple times
//           setFlashMessage({
//             message: 'Thank you! Your email has been successfully submited.',
//             error: false,
//           })
//           setEmail('')
//           return Promise.resolve()
//         } else {
//           return Promise.reject()
//         }
//       })
//       .catch(err => {
//         setFlashMessage({
//           message: 'Something went wrong. Could not submit email.',
//           error: true,
//         })
//       })
//   }

//   return (
//     <section className="container mt-typist56 mt-md-typist64 mt-xl-typist96">
//       <div className="row justify-content-center">
//         <div className="col-12 col-md-8 col-lg-6">
//           <h1 className="mb-md-typist32 text-center fs-2">Contact Us</h1>
//           <p>
//             Learn why faculty everywhere are switching their curriculum to
//             Typist. Fill out our form below and Matt, our Founder, will get back
//             to you shortly.
//           </p>
//           <p>
//             Alternatively, write Matt directly:{' '}
//             <a href="mailto:matt@typistapp.ca">matt@typistapp.ca</a>
//           </p>

//           <div className="row">
//             <div className="col-12 ">
//               <form
//                 name="contact"
//                 method="POST"
//                 data-netlify="true"
//                 data-netlify-honeypot="bot-field"
//                 className="w-100"
//                 onSubmit={handleSubmit}
//               >
//                 {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
//                 <input type="hidden" name="form-name" value="contact" />
//                 <div className="mb-3 w-100">
//                   <label
//                     htmlFor="contactUsName"
//                     className="form-label"
//                     required
//                   >
//                     Name
//                   </label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     onChange={e => setName(e.target.value)}
//                     id="contactUsName"
//                   />
//                   {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
//                 </div>
//                 <div className="mb-3 w-100">
//                   <label htmlFor="contactUsEmail" className="form-label">
//                     Email address
//                   </label>
//                   <input
//                     type="email"
//                     name="email"
//                     onChange={e => setEmail(e.target.value)}
//                     className="form-control"
//                     id="contactUsEmail"
//                     required
//                   />
//                   {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="contactUsOrganization" className="form-label">
//                     Organization
//                   </label>
//                   <input
//                     type="text"
//                     name="organization"
//                     onChange={e => setOrganization(e.target.value)}
//                     className="form-control"
//                     id="contactUsOrganization"
//                   />
//                   {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
//                 </div>
//                 <div className="mb-3">
//                   <label htmlFor="message">Message (optional)</label>
//                   <textarea
//                     className="form-control"
//                     onChange={e => setMessage(e.target.value)}
//                     id="message"
//                     rows="6"
//                     name="message"
//                     value="Hi Matt – I'd like to learn more about Typist. Looking forward to connecting."
//                   />
//                 </div>
//                 {/* <div className="mb-3 form-check">
//                                 <input type="checkbox" className="form-check-input" id="exampleCheck1" />
//                                 <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
//                             </div> */}
//                 <button type="submit" className="btn btn-primary">
//                   Submit
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

// export default ContactForm
