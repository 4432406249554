import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

// This has same implementation as bootstrap's page
// Make sure this element is inside and as close to the opening of the <body> element as possible
const SkipToMainContent = () => (
  <div className="visually-hidden-focusable">
    <div className="container">
      <a className="d-inline-flex p-2 m-1" href="#content">
        Skip to main content
      </a>
    </div>
  </div>
)

const ContactPage = ({ data }) => (
  <>
    <SkipToMainContent></SkipToMainContent>
    <Layout>
      <SEO title="Contact" />
      <ContactForm />
    </Layout>
  </>
)

export default ContactPage